import axios from 'axios';

const API_URL = '/orders/';

// Get all orders
const getOrders = async (token) => {
    const config = {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  };
  const response = await axios.get(API_URL, config);
  return response.data;
};

const getUnpaidOrders = async (token) => {
  const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
};
const response = await axios.get(API_URL + "unpaid", config);
return response.data;
};

// Get order by id
const getOrder = async (token, orderId) => {
    const config = {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  };
  const response = await axios.get(`${API_URL}${orderId}`, config);
  return response.data;
};

const getOrderPublic = async (orderId) => {
  const response = await axios.get(`${API_URL}public/${orderId}`);
  return response.data;
};

// Create a new order
const createOrder = async (token, orderData) => {
    const config = {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  };
  const response = await axios.post(`${API_URL}create`, orderData, config);
  return response.data;
};

// Initiate Stripe payment session
const initiateStripe = async (orderData) => {
  const response = await axios.post(`${API_URL}initiate-stripe`, orderData);
  return response.data;
};

const toggleStatus = async (token, data) => {
  const config = {
    headers: {
        Authorization: `Bearer ${token}`,
    },
};
  const response = await axios.post(`${API_URL}toggle-status`, data, config);
  return response.data;
};

const contactUs = async (contactData) => {
  const response = await axios.post(`${API_URL}contact-us`, contactData);
  return response.data;
};

const resendEmail = async (id) => {
  const response = await axios.post(`${API_URL}resend-email/${id}`);
  return response.data;
};

// Fetch shipping labels for selected orders
const getShippingLabels = async (ids) => {
  // const config = {
  //   headers: {
  //       Authorization: `Bearer ${token}`,
  //   },
  // };

  const response = await axios.post(`${API_URL}shipping-labels`, { ids });
  return response.data;
};

const getCustomInvoice = async (data) => {
  const response = await axios.post(`${API_URL}create-custom-invoice`, data);
  return response.data;
};

const exportOrders = async (ids) => {
  const response = await axios.post(`${API_URL}excel`, { ids }, { responseType: 'blob' });
  return response.data;  // This will return a Blob object
};


const orderService = {
  getOrders,
  getOrder,
  createOrder,
  initiateStripe,
  getOrderPublic,
  toggleStatus,
  contactUs,
  resendEmail,
  getUnpaidOrders,
  getShippingLabels,
  exportOrders,
  getCustomInvoice
};

export default orderService;
