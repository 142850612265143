import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import MenuItem from './style-components/MenuItem';
import { IoCartOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import CartCounter from './style-components/CartCounter';
import HeaderClient from './header-components/HeaderClient';
import HeaderAdmin from './header-components/HeaderAdmin';
import { getTexts } from '../features/texts/textSlice';
import Spinner from './Spinner';

function Header() {
    const [admin, setAdmin] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth < 700);
    const [isHome, setIsHome] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const dispatch = useDispatch();

    const location = useLocation();

    const { texts, isLoading, isError, isSuccess, message } = useSelector((state) => state.texts);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
      if (user && user.type === 'admin') {
        setAdmin(true);
      }
    }, [user]);

    useEffect(() => {
        dispatch(getTexts());
    }, []);

    useEffect(() => {
      const handleResize = () => {
        setMobile(window.innerWidth < 1100);
      };

      // Attach the event listener
      window.addEventListener('resize', handleResize);

      // Cleanup function
      return () => {
        // Remove the event listener
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      // Check if the current path is the home path
      setIsHome(location.pathname === '/');
    }, [location]);

    useEffect(() => {
      const handleScroll = () => {
        setScrolled(window.scrollY > 50);
      };

      // Attach the scroll event listener
      window.addEventListener('scroll', handleScroll);

      // Cleanup function
      return () => {
        // Remove the scroll event listener
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const getValueByTag = (tag) => {
      const textObject = texts.find(text => text.tag === tag);
      return textObject && textObject.value ? { __html: textObject.value } : null;
    }

    if (isLoading) {
        return <Spinner />
    }

    const bannerContent = getValueByTag('header-baneris');

    return (
        <div className={`header ${isHome && !admin ? 'light' : ''}`}>
          { !admin && bannerContent && (
            <div className={`baneris ${!isHome ? 'light' : ''} ${scrolled ? 'hidden' : ''}`} dangerouslySetInnerHTML={bannerContent}></div>
          )}

          {admin ? <HeaderAdmin /> : <HeaderClient mobile={mobile} />}
        </div>
    );
}

export default Header;